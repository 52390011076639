<template>
  <section class="page-container">
    <div ref="toolbar" class="toolbar">
      <el-form :inline="true" :model="findCards">
        <el-form-item>
          <el-input v-model="findCards.cardId" placeholder="卡号" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="findCards.cardSecret" placeholder="卡密" />
        </el-form-item>
        <el-form-item>
          <el-select v-model="findCards.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="findCards.cardType" placeholder="请选择卡密类型">
            <el-option
              v-for="item in cardTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search"> 查询 </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addCard = true"> 新增 </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd"> 导出 </el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="cards"
        v-loading = "loading"
        element-loading-background = "rgba(0, 0, 0, 0.5)"
        element-loading-text = "数据正在加载中"
        element-loading-spinner = "el-icon-loading"
        style="width: 100%">
        <el-table-column
          prop="cardId"
          label="卡号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="cardSecret"
          label="卡密"
          width="180">
        </el-table-column>
        <el-table-column
          label="卡密类型"
          width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.cardType === 1">灵气卡</span>
            <span v-if="scope.row.cardType === 2">会员卡</span>
            <span v-if="scope.row.cardType === 3">邀请码</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="denomination"
          label="卡密参数"
          width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.cardType === 1">{{scope.row.denomination}}</span>
            <span v-if="scope.row.denomination === 1">黄金会员-{{scope.row.memberTime}}</span>
            <span v-if="scope.row.denomination === 2">白金会员-{{scope.row.memberTime}}</span>
            <span v-if="scope.row.denomination === 5">赞助会员-{{scope.row.memberTime}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">未使用</span>
            <span v-if="scope.row.status === 1">已使用</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="userId"
          label="使用人">
        </el-table-column>
      </el-table>
    </div>
    <div v-if="page.total > 0" ref="pagebar" class="pagebar">
      <el-pagination
        :page-sizes="[10,20, 50, 100, 300]"
        :current-page="page.page"
        :page-size="page.limit"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageChange"
        @size-change="handleLimitChange"
      />
    </div>
    <el-dialog
      title="新增卡密"
      :visible.sync="addCard"
      width="30%"
      :before-close="addCardClose">
      <div>
        <el-form :inline="true" :model="addCardForm">
          <el-form-item>
            <el-select v-model="addCardForm.cardType" @change="changeCardType" placeholder="请选择卡密类型">
              <el-option
                v-for="item in cardTypeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-if="addCardForm.cardType === '1'" v-model="addCardForm.denomination" placeholder="请选择卡密额度">
              <el-option
                v-for="item in scoreDenomination"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-if="addCardForm.cardType === '2'" v-model="addCardForm.denomination" placeholder="请选择会员类型">
              <el-option
                v-for="item in memberDenomination"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-if="addCardForm.cardType === '2'" v-model="addCardForm.memberTime" placeholder="请选择会员时长">
              <el-option
                v-for="item in memberTime"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="addCardForm.generateNum" placeholder="生成数量" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCard = false">取 消</el-button>
        <el-button type="primary" @click="addCardSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      addCardForm:{
        cardType:'1',
        denomination:'',
        generateNum:'',
        memberTime:''
      },
      scoreDenomination:[
        {
        label:'100灵气',
        value:"100"
        },
        {
          label:'150灵气',
          value:"150"
        },
        {
          label:'300灵气',
          value:"300"
        },
        {
          label:'500灵气',
          value:"500"
        },
        {
          label:'1000灵气',
          value:"1000"
        }
      ],
      memberDenomination:[
        {
          label:'赞助会员',
          value:"5"
        },
        {
          label:'黄金会员',
          value:"1"
        },
        {
          label:'白金会员',
          value:"2"
        }
      ],
      memberTime:[
        {
          label:'月卡',
          value:"31"
        },
        {
          label:'年卡',
          value:"365"
        },
      ],
      loading:false,
      addCard:false,
      findCards:{},
      cards:[],
      page:{
        total:0,
        page:1,
        limit:10,
      },
      statusOption:[
        {
          label:'未使用',
          value:"0"
        },
        {
          label:'已使用',
          value:"1"
        }
      ],
      cardTypeOption:[
        {
          label:'灵气卡',
          value:"1"
        },
        {
          label:'会员卡',
          value:"2"
        },
        {
          label:'邀请码',
          value:"3"
        }
      ]
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    async list(){
      let me = this
      this.loading = true
      try{
        let list = await this.axios.form("/api/admin/card/cards", {
          cardId : me.findCards.cardId,
          cardSecret : me.findCards.cardSecret,
          cardType : me.findCards.cardType,
          status : me.findCards.status,
          pageNum : me.page.page,
          pageSize: me.page.limit,
        })
        me.cards = list.cards
        me.page = {
          total: list.page.total,
          page: list.page.page,
          limit: list.page.limit,
        }
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
      this.loading = false
    },
    handlePageChange(val){
      this.page.page = val
      this.list()
    },
    handleLimitChange(val){

    },
    search(){
      let me = this
      me.page.page = 1
      me.page.limit = 10
      this.list()
    },
    addCardClose(){
      this.addCard = false
    },
    changeCardType(val){
      if (val === '1'){
        this.addCardForm.denomination = '100'
      }else if (val === '2'){
        this.addCardForm.denomination = '1'
      }else {
        this.addCardForm.denomination = '0'
      }
    },
    async addCardSubmit(){
      let me = this
      try{
        let res = await this.axios.form("/api/admin/card/generate",me.addCardForm)
        me.$message({ message: "生成成功", type: "success" });
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
      this.addCard = false
      await me.list()
    }
  }
}
</script>

<style scoped>

</style>
